export const alreadyAssignedFilter = (
        employee: any,
        stations: any,
        startTime?: number,
        endTime?: number
    ) => {
    //For a given employee, walk through all positions and return false if
    //they are already assigned to a position which overlaps this one.

    let alreadyAssigned = false;
    if (startTime && endTime) {
        stations.forEach((station) => {
            station.positions.forEach((position) => {
                if (position.employee && position.employee.identityId === employee.identityId) {
                    if (!(position.start >= endTime || position.end <= startTime)) {
                        alreadyAssigned = true;
                    }
                }
            });
        });
    }

    return !alreadyAssigned;
}